import Abyss from "./Abyss";
import "./App.css";
import Message from "./Message";

const App = () => (
  <>
    <Message />
    <Abyss />
  </>
);

export default App;
