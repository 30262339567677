import { useState } from "react";
import "./Message.css";

const Message = () => {
  const [message, setMessage] = useState(
    decodeURI(window.location.hash.replace(/^#/, ""))
  );
  const [animating, setAnimating] = useState(message !== "");

  return (
    <div
      className="message"
      style={{
        animationName: animating ? "into-the-abyss" : undefined,
      }}
      onAnimationEnd={() => {
        setMessage("");

        setTimeout(() => setAnimating(false), 1000);

        // remove any hash from the URL
        window.history.pushState(
          "",
          document.title,
          window.location.pathname + window.location.search
        );
      }}
    >
      {animating ? (
        message
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setAnimating(true);
          }}
        >
          <input
            className="messageInput"
            placeholder="For the abyss..."
            autoFocus
            enterKeyHint="send"
            type="text"
            value={message}
            onChange={(e) => setMessage(e.currentTarget.value)}
          />
        </form>
      )}
    </div>
  );
};

export default Message;
